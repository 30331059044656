import moment from "moment";
import qs from "qs";
import jwt from "expo-jwt";
export const appName = "KalviSalai";
//end trailing slash is must
export const S3_BUCKET_HOME_PATH = "tndls/";
export const PS_WEB = "21R91541sD5411H0G5";

// Colleges

//tirunelveli
// export const COLLEGE_UUID = "aeb2cf65-5759-4529-b818-2af551b7db96";
// export const DB_POSTFIX = "glctvl";

//Madurai
// export const COLLEGE_UUID = "51a09f77-1d54-4f47-90d0-4af8376f806e";
// export const DB_POSTFIX = "glcmdu";

//Trichy
// export const COLLEGE_UUID = "857fe355-cbdc-408d-ac50-9dced0dbff75";
// export const DB_POSTFIX = "glctry";

//Pattaraiperumbudur
// export const COLLEGE_UUID = "eb0c5752-c7d9-4ae9-b2d1-78a80a8af6e2";
// export const DB_POSTFIX = "glcppr";

//Pudupkkam
export const COLLEGE_UUID = "afdc8beb-8b26-484f-b81d-9efecc6d1e29";
export const DB_POSTFIX = "glcpkm";

//Coimbatore
// export const COLLEGE_UUID = "fc707647-29c6-49ba-ab43-e61d760759b2";
// export const DB_POSTFIX = "glccbe";

//Chengalpattu
// export const COLLEGE_UUID = "32e5a902-3007-4aa1-8d75-f296e9f1676a";
// export const DB_POSTFIX = "glccgl";

//Vellore
// export const COLLEGE_UUID = "a82b561a-1e66-41a2-83c0-96a8ec452170";
// export const DB_POSTFIX = "glcvlr";

//Villupuram
// export const COLLEGE_UUID = "606f139c-4235-4e55-8f5f-c3c013105663";
// export const DB_POSTFIX = "glcvpm";

//Dharmapuri
// export const COLLEGE_UUID = "7de40911-d0b8-47ce-9822-6148370c455c";
// export const DB_POSTFIX = "glcdpi";

//Ramanathapuram
// export const COLLEGE_UUID = "3c756874-4426-45df-8dc3-294f914c273c";
// export const DB_POSTFIX = "glcrmd";

//Salem
// export const COLLEGE_UUID = "7ebfc89d-30ca-4812-8f26-5a832733ec01";
// export const DB_POSTFIX = "glcslm";

//Namakkal
// export const COLLEGE_UUID = "5340fd36-78d2-4a67-b3d4-eeca0d0c784e";
// export const DB_POSTFIX = "glcnmk";

//Theni
// export const COLLEGE_UUID = "bcb5e02a-51d5-482d-9180-ddabc71fedd5";
// export const DB_POSTFIX = "glctni";

//Karaikudi
// export const COLLEGE_UUID = "9af3c3c2-c51f-4603-a4f3-4419352157df";
// export const DB_POSTFIX = "glckkdi";

export const SITE_FILE_DOWNLOAD_DIR = "files_list/";

// export const baseUrl = "http://localhost/git/tndlserp/api/";
export const baseUrl = "https://portal.tndls.ac.in/api/";
export const siteUrl = "https://portal.tndls.ac.in/";

export const LOADER_LOGO = require("../assets/book_loader.gif");

export const NO_IMAGE_LOGO_WIDE = require("../assets/img/no_course_logo.jpg");

// export const TOP_WIDE_LOGO = require("../assets/logo.jpg");
export const TOP_WIDE_LOGOK = require("../assets/ns_logo_autonomous.png");
export const TOP_WIDE_LOGO_WHITE = require("../assets/nslogo_white.png");

export const topWideLogoPath = require("../assets/counter-bg.jpg");

export const TOP_WIDE_KAM = require("../assets/kamarajar.png");

export const TOP_WIDE_TIRU = require("../assets/cta-bg.jpg");

export const TOP_WIDE_NSCAS = require("../assets/pic1.png");

export const PARTNER_PICONE = require("../assets/partner/1.png");
export const PARTNER_PITOW = require("../assets/partner/2.png");
export const PARTNER_PITHREE = require("../assets/partner/3.png");
export const PARTNER_PIFOUR = require("../assets/partner/4.png");
export const PARTNER_PIFIVE = require("../assets/partner/5.png");

export const ICON_ADMISSION = require("../assets/img/admission.png");
export const THIRUVALLUVAR_IMAGE = require("../assets/thiruvalluvar.png");

export const IMAGES_512 = {
  ADMINSTRATION: require("../assets/512/adminstration.png"),
  CITIZEN: require("../assets/512/citizen.png"),
  EMPOWERMENT: require("../assets/512/empowerment.png"),
  PLACEMENT: require("../assets/512/placement.png"),
  NO_IMAGE: require("../assets/512/no-pictures.png"),
  NEW_GIF: require("../assets/img/new-animated-gif.gif"),
};

export const CardFixedTop = ({ title, children }) => {
  return (
    <div className="card card-fixed-top">
      <div className="card-body">
        <div className="d-flex  justify-content-between">
          <div style={{ padding: "5px 0 0 8px" }}>
            <b>{title}</b>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export const getFileLiveUrl = (item) => {
  let url = siteUrl + SITE_FILE_DOWNLOAD_DIR;
  if (item && item.Key) url += item.Key;
  else url += item;
  return url;
};

export const printDocument = (printId) => {
  var content = document.getElementById(printId);
  var pri = document.getElementById("print_frame").contentWindow;
  pri.document.open();
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.focus();
  pri.print();
};

/**
 * setLs
 * to store the user inputs into localStorage
 **/
export function setLs(key, value) {
  localStorage.setItem(key, value);
}

/**
 * getLs
 * to get the stored values from localStorage
 **/
export function getLs(key) {
  return localStorage.getItem(key) || false;
}

export function getAscSortOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

/**
 * getDescSortOrder
 * to sort an array by particular field
 * @param Property of an array
 * @return int
 */
export function getDescSortOrder(prop) {
  return function (a, b) {
    if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

/**
 * removeBothSlash
 * to remove the first and last slash from a string
 * @param1 $string
 * @return String
 */
export function removeBothSlash(str) {
  return str.replace(/^\/|\/$/g, "");
}

export function removeDoubleSlas(str) {
  return str.split("//").join("/");
}

/**
 * capitalizeFirst
 * to capitalize the first letter of the word
 * @param1 $str (string)
 * @return string
 */
export function capitalizeFirst(str) {
  if (str == "" || str == null || str == " ") {
    return "";
  }

  str = str.toLowerCase();
  //return str.charAt(0).toUpperCase() + str.slice(1);
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}

/**
 * upperCase
 * to conver the string to upper case
 * @param1 $str (string)
 * @return string
 */
export function upperCase(str) {
  if (str == "" || str == null || str == " " || str.length < 1) return "";
  return str.toString().toUpperCase();
}

/**
 * lowerCase
 * to conver the string to lower case
 * @param1 $str (string)
 * @return string
 */
export function lowerCase(str) {
  if (str == "" || str == null || str == " ") return "";
  return str.toString().toLowerCase();
}

/**
 * makeUrl
 * to convert the string into url
 * to remove all the special characters and remove space
 *
 * @param1 $str
 * @return String
 */
export function makeUrl(str) {
  try {
    str = str.replace(/[&\/\\#,+()$~%.\'":*?<>{}]/g, "");
    str = str.replace(/ /g, "-");
    return str.toLowerCase();
  } catch (error) {
    console.log(error);
  }
}

export function groupByMultiple(array, f) {
  var groups = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

/**
 * yesorNo
 * to get the value yes or no form the boolean
 * @param1 $val (bool) true,false, 1,0
 * @param2 $withColor (true,false) default false
 * @return String
 */
export function yesorNo(val) {
  if (val == "" || val == null || val == " ") return "No";
  if (val == "1" || val == true) return "Yes";
  return "No";
}

/**
 * isNullOrEmpty
 * to check the given value is null or empty
 * @param $val
 * @return Boolean
 */
export function isNullOrEmpty(val) {
  try {
    if (val == "" || val == null || val == " " || val == NaN) return false;
    return true;
  } catch (error) {
    console.log(error);
    return true;
  }
}

/**
 * calculateMessageCount
 * to calculate letters count
 * @param1 $textArea
 * @param2 $displayArea
 * @return HTML
 */
export function calculateMsgCount(textAreaId, divId) {
  try {
    const singleSmsLength = 160;
    const ele = document.getElementById(textAreaId);
    const charLength = ele.value.length;
    const msgCount = Math.ceil(charLength / singleSmsLength);
    document.getElementById(divId).innerHTML = `${charLength} / ${msgCount}`;
  } catch (error) {
    console.log(error);
  }
}

/**
 * momentDate
 * to convert a date format using moment
 * @param1 $date
 * @param2 $format
 * @return date
 */
export function momentDate(value, toFormat = "DD/MMM/YYYY") {
  try {
    if (
      value == "0000-00-00" ||
      value == "" ||
      value == " " ||
      value == null ||
      value == NaN
    ) {
      return "";
    }

    // var d = new Date(value).getTime();
    return moment(value).format(toFormat);
  } catch (error) {
    console.log(error);
    return "";
  }
}

/**
 * integerKeyPress
 * to handle textbox key press event and check whether the input is integer or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerKeyPress(e) {
  if (e.which != 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * decimalKeyPress
 * to handle textbox key press event and check whether the input is decimal or not
 *
 * @param EVENT
 * @return NULL
 */
export function decimalKeyPress(e) {
  if (e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Decimal numbers only");
    e.preventDefault();
    return false;
  }
  if (e.which == 46 && e.target.value.indexOf(".") != -1) {
    // showAdvice(this, "Only one period allowed in decimal numbers");
    e.preventDefault();
    return false; // only one decimal allowed
  }
}

/**
 * lettersOnly
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function lettersOnly(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

/**
 * nameWithDotAndHypen
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function nameWithDotAndHypen(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    e.which != 45 &&
    e.which != 46 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

/**
 * integerIndMobile
 * to handle textbox key press event and check whether the input is integer and less than 10 characters or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerIndMobile(e) {
  const len = e.target.value.length;
  if (len >= 10) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function integerAadhar(e) {
  const len = e.target.value.length;
  if (len >= 12) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 10 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function integerGst(e) {
  const len = e.target.value.length;
  if (len >= 15) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 10 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

export function removeExtension(filename) {
  if (filename && filename != "" && filename != null && filename.length > 3)
    return filename.split(".").slice(0, -1).join(".");
}

export function calculateAge(dob) {
  try {
    var a = moment();
    var b = moment(moment(dob), "YYYY");
    var diff = a.diff(b, "years");
    return diff;
  } catch (er) {}
}

export function replaceBulk(str, findArray, replaceArray) {
  var i,
    regex = [],
    map = {};
  for (i = 0; i < findArray.length; i++) {
    regex.push(findArray[i].replace(/([-[\]{}()*+?.\\^$|#,])/g, "\\$1"));
    map[findArray[i]] = replaceArray[i];
  }
  regex = regex.join("|");
  str = str.replace(new RegExp(regex, "g"), function (matched) {
    return map[matched];
  });
  return str;
}

/**
 * numberToWords
 * to convert number to words
 *
 * @param1 number (int)
 * @return string
 */
export function numberToWords(amount) {
  if (!amount) return "";
  const words = new Array();
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  const atemp = amount.split(".");
  const number = atemp[0].split(",").join("");
  const n_length = number.length;
  let words_string = "";
  if (n_length <= 9) {
    const n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    const received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    let value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += `${words[value]} `;
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}

export function testResult(
  condition,
  fromRange,
  toRange,
  result,
  gender = false
) {
  if (!condition) return true;

  try {
    var from = fromRange; //(gender=='F') ? test.female_range_from : test.range_from;
    var to = toRange; //(gender=='F') ? test.female_range_to : test.range_to;
    var cond = condition; //(gender=='F') ? test.female_reference_condition : test.reference_condition;

    if (cond == "Nothing" || cond == "Nill") {
      return false;
    }

    if (cond == "Equal") {
      return result == from || result == to ? true : false;
    }
    if (cond == "Contain") {
      return result == from || result == to ? true : false;
    }
    if (cond == "Less_Than") {
      return parseFloat(result) < parseFloat(from) ||
        parseFloat(result) < parseFloat(to)
        ? true
        : false;
    }
    if (cond == "Greater_Than") {
      return parseFloat(result) > parseFloat(from) ||
        parseFloat(result) > parseFloat(to)
        ? true
        : false;
    }
    if (cond == "Between") {
      return parseFloat(result) >= parseFloat(from) &&
        parseFloat(result) <= parseFloat(to)
        ? true
        : false;
    }
  } catch (er) {}
}

export function getDrCrColor(amt) {
  amt = parseFloat(amt);
  if (amt == 0) return "text-dark";
  else if (amt > 0) return "text-dark-success";
  else if (amt < 0) return "text-dark-danger";
}

export function typeName(str) {
  if (str && str != "" && str != null && str != NaN) {
    str = str.split("_").join(" ");
    str = str.toLowerCase();
    //return str.charAt(0).toUpperCase() + str.slice(1);
    return str.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  }
  return str;
}

export function startDateOfMonth(month, toFormat = "YYYY-MM-DD") {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  return moment(firstDay).format(toFormat);
}

export function endDateOfMonth(month, toFormat = "YYYY-MM-DD") {
  var date = new Date();
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return moment(lastDay).format(toFormat);
}

export function emptyRowsToAdd() {
  let height = window.outerHeight;
  return Math.round((window.outerHeight - 250) / 20).toFixed(0);
}

export function queryParam(search) {
  let rv = [];
  if (search.length < 1) {
    return rv;
  }
  let str = search; //props.location.search;
  str = str.replace(/\?/g, "");
  let ar = str.split("&");
  if (ar && ar.length > 0) {
    ar.map((item, i) => {
      let m = item.split("=");
      rv[m[0]] = m[1];
    });
  }
  return rv;
}

export function formToObject(form) {
  const data = new FormData(form);
  const value = Object.fromEntries(data.entries());
  return value;
}

export function yearBySem(num) {
  if (num == "1" || num == "2") return "I-Year";
  else if (num == "3" || num == "4") return "II-Year";
  else if (num == "5" || num == "5") return "III-Year";
}

export function semesterValue(num) {
  if (num == "1") return "SEM-I";
  else if (num == "2") return "SEM-II";
  else if (num == "3") return "SEM-III";
  else if (num == "4") return "SEM-IV";
  else if (num == "5") return "SEM-V";
  else if (num == "6") return "SEM-VI";
}

export function timeTableDayFromNumber(num) {
  if (num == "1") return "Mon";
  else if (num == "2") return "Tue";
  else if (num == "3") return "Wed";
  else if (num == "4") return "Thu";
  else if (num == "5") return "Fri";
  else if (num == "6") return "Sat";
}

export function customSorting(
  arrayOfDatas,
  sortOrderDatas,
  sortFieldName = "name"
) {
  const sortByObject = sortOrderDatas.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

  const customSort = arrayOfDatas.sort(
    (a, b) => sortByObject[a[sortFieldName]] - sortByObject[b[sortFieldName]]
  );

  return customSort;
}

export function yearByBatch(batch) {
  let currentMonth = moment(new Date()).format("MM");
  let currentYear = moment(new Date()).format("YYYY");
  if (parseInt(currentMonth) < 6) currentYear = parseInt(currentYear) - 1;
  let b = batch.split("-");
  if (b && b.length > 0) {
    if (b[0] == currentYear) return "I-Year";
    else if (b[0] == currentYear - 1) return "II-Year";
    else if (b[0] == currentYear - 2) return "III-Year";
  } else return "";
}

export function objectToQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function selectElement(el, all = false) {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
}

export function onElementClick(type, el, listener, all = false) {
  let selectEl = selectElement(el, all);
  if (selectEl) {
    if (all) {
      selectEl.forEach((e) => e.addEventListener(type, listener));
    } else {
      selectEl.addEventListener(type, listener);
    }
  }
}

export function jsonToQuery(params) {
  return qs.stringify(params);
}

export const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const removeFieldFromObjects = (objects, fieldToRemove) => {
  return objects.map((obj) => {
    const { [fieldToRemove]: _, ...rest } = obj; // Destructure and exclude the field
    return rest; // Return the new object without the field
  });
};

export const sortCommaSeparateValues = (inputValue) => {
  try {
    // Split the comma-separated string into an array
    const valuesArray = inputValue.split(",");

    // Sort the array in ascending order
    valuesArray.sort((a, b) => a.localeCompare(b));

    // Join the sorted array back into a comma-separated string
    const sortedValues = valuesArray.join(",");

    // Update state with the sorted values
    return sortedValues;
  } catch (er) {}
};

export const cdnFileLink = (fielUrl) => {
  try {
    return `https://portal.tndls.ac.in/${SITE_FILE_DOWNLOAD_DIR}/${fielUrl}`;
    return `${siteUrl}/${SITE_FILE_DOWNLOAD_DIR}/${fielUrl}`;
  } catch (er) {}
};

export const apiFolderFileLinks = (fielUrl) => {
  try {
    return `${baseUrl}/${fielUrl}`;
  } catch (er) {}
};

export function localSettingsValues(key, defaultReturn = false, college_uuid) {
  try {
    let settings = [];
    if (getLs("billnt_settings") && getLs("billnt_settings") !== "undefined") {
      let set = getLs("billnt_settings");
      if (!set.includes("{")) {
        let decoded = jwt.decode(set, PS_WEB);
        settings = decoded;
      }
    }

    let m =
      settings &&
      settings.find(
        (item) => item.field_name == key && item.college_uuid == college_uuid
      );

    let v = m && m.field_name ? m.field_value : false;

    return v ? v : defaultReturn;
  } catch (er) {}
}

export const courseName = (courseInfo) => {
  let rv = "";
  try {
    if (courseInfo.course_short_name) rv = courseInfo.course_short_name;
    else {
      rv = courseInfo.degree_name + " - " + courseInfo.course_name;
      //if (upperCase(courseInfo.course_type) == "REGULAR") rv += " (R)";
      //else if (upperCase(courseInfo.course_type) == "SELF") rv += " (SF)";
    }
  } catch (er) {}
  return rv;
};
